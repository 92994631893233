<template>
  <i :class="[
             `fa-${icon_type}`,
             $props.weight  === 'light' ? 'fal font-light' : 'fas',
             $props.classes,
             font_class ? 'font-icons' : 'font-brands'
             ]"></i>
</template>

<script>
export default {
  name: "Icons",
  props: ["type", "weight", "classes"],
  data() {
    return {
      icon_type: '',
      font_class: true,
      font_class_brands: ['waze', 'pushed']
    }
  },
  created() {
    this.font_class_brands.forEach((c) => {
      if(this.$props.type === c) {
        this.font_class = false
      }
    })
    if(this.$props.type) {
      this.icon_type = this.$props.type.replace('_', '-');
    }
  }
}
</script>
